<style scoped lang="less">
  .content {
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
  }
  .text-field, .text-value {
    line-height: 3;
    white-space: wrap;
  }
  .row {
    white-space: wrap;
    min-width: 30%;
    margin-right: 3%;
    word-break: keep-all;
  }
</style>

<template>
  <r-card hborder>
    <span slot="title">党籍信息</span>
    <div class="content">
        <div class="row">
          <span class="text-field">入党时所在支部：</span>
          <span class="text-value">{{otherInfo.rdsszzb || '-'}}</span>
        </div>
        <div class="row">
          <span class="text-field">入党介绍人：</span>
          <span class="text-value">{{otherInfo.rdjsr || '-'}}</span>
        </div>
        <div class="row">
          <span class="text-field">转入当前支部日期：</span>
          <span class="text-value">{{(otherInfo.zrdqzbrq || '-').substr(0, 10)}}</span>
        </div>
        <div class="row">
          <span class="text-field">转出党支部名称：</span>
          <span class="text-value">{{otherInfo.zcdzbmc || '-'}}</span>
        </div>
        <div class="row">
          <span class="text-field">现任党内职务：</span>
          <span class="text-value">{{otherInfo.xrdlzw || '-'}}</span>
        </div>
        <div class="row">
          <span class="text-field">流出日期：</span>
          <span class="text-value">{{(otherInfo.lcrq || '-').substr(0, 10)}}</span>
        </div>
        <div class="row">
          <span class="text-field">外出流向：</span>
          <span class="text-value">{{otherInfo.wclx || '-'}}</span>
        </div>
        <div class="row">
          <span class="text-field">外出原因：</span>
          <span class="text-value">{{otherInfo.wcyy || '-'}}</span>
        </div>
        <div class="row">
          <span class="text-field">流动党员活动证编号：</span>
          <span class="text-value">{{otherInfo.hdzbh || '-'}}</span>
        </div>
        <div class="row">
          <span class="text-field">流入单位：</span>
          <span class="text-value">{{otherInfo.lrdw || '-'}}</span>
        </div>
        <div class="row">
          <span class="text-field">流入单位党组织联系人及联系方式：</span>
          <span class="text-value">{{otherInfo.lrdwlxfs || '-'}}</span>
        </div>
        <div class="row">
          <span class="text-field">出国日期：</span>
          <span class="text-value">{{(otherInfo.cgrq || '-').substr(0, 10)}}</span>
        </div>
        <div class="row">
          <span class="text-field">出国原因：</span>
          <span class="text-value">{{otherInfo.cgyy || '-'}}</span>
        </div>
        <div class="row">
          <span class="text-field">出国后与组织联系情况：</span>
          <span class="text-value">{{otherInfo.cghlxqk || '-'}}</span>
        </div>
        <div class="row">
          <span class="text-field">党籍处理方式：</span>
          <span class="text-value">{{otherInfo.djclfs || '-'}}</span>
        </div>
        <div class="row">
          <span class="text-field">回国日期：</span>
          <span class="text-value">{{(otherInfo.hgrq || '-').substr(0, 10)}}</span>
        </div>
        <div class="row">
          <span class="text-field">恢复组织生活情况：</span>
          <span class="text-value">{{otherInfo.hfzzshqk || '-'}}</span>
        </div>
        <div class="row">
          <span class="text-field">存入档案材料：</span>
          <span class="text-value">{{otherInfo.crdacl || '-'}}</span>
        </div>
        <div class="row">
          <span class="text-field">保管单位：</span>
          <span class="text-value">{{otherInfo.bgdw || '-'}}</span>
        </div>
      </div>
  </r-card>
</template>

<script>
import RCard from '../components/card'
// import RImgs from '../components/imgs'
import { getWorkerInfo } from '../libs'
import { getAge } from '@/syslib/tools'
import { loadImgs } from '@/components/base/file/libv2'

export default {
  components: { RCard },
  data () {
    return {
      data: {},
      idCardSources: null,
      otherInfo: {}
    }
  },
  computed: {
    id () {
      return this.$route.query.id
    },
    age () {
      return getAge(this.data.birth, new Date())
    }
  },
  watch: {
    id () {
      this.loadData()
    }
  },
  methods: {
    loadData () {
      getWorkerInfo(this.id).then(async data => {
        this.data = data
        this.otherInfo = data.otherInfo || {}
        const items = []
        if (data.idCardFrontFileId) {
          items.push({
            name: '正面',
            fileIds: data.idCardFrontFileId
          })
        }
        if (data.idCardBackFileId) {
          items.push({
            name: '背面',
            fileIds: data.idCardBackFileId
          })
        }
        this.idCardSources = await loadImgs(items, (item) => item.name || '身份证')
      })
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
